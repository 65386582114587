
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const alertStyle = {
    position: 'fixed',
    right: '20px',
    top: '80px',
    width: '420px',
    zIndex: 5000,
}

const Alert = ({ alerts }) => {
    return (<div style={alertStyle}>
        {
            alerts !== null && alerts.length > 0 && alerts.map(
            alert => (
                <div key={alert.id} className={`alert alert-${alert.alertType}`}>
                    {alert.msg}
                </div>
            ))
        }
    </div>)
}
    

Alert.propTypes = {
    alerts: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
    alerts: state.alert
})

export default connect(mapStateToProps)(Alert);
