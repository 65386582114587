import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logout } from '../../actions/auth';

const Navbar = ({ logout }) => {

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light justify-content-between" style={{ paddingLeft: '80px' }}>
            <a className="navbar-brand" href="/dashboard">
                <img src="isotipo.png" alt="" height="25" style={{ marginRight: '0.5rem' }} />
            </a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <a className="nav-link" onClick={logout} href="/">Cerrar sesión</a>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

Navbar.propTypes = {
    logout: PropTypes.func.isRequired,
}

export default connect(null, {
    logout
})(Navbar);