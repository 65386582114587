import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from './Loader'

const Layout = ({ auth }) => {

    const loader = useSelector(state => state.loader);

    const layoutAdmin = (
        <Fragment>
            {loader && <Loader></Loader>}
            <Sidebar></Sidebar>
            <Navbar></Navbar>
        </Fragment>
    )

    const layoutGuest = (<Fragment></Fragment>);

    if (!auth.loading && auth.isAuthenticated) {
        return layoutAdmin;
    } else {
        return layoutGuest;
    }

}

const mapStateToProps = state => ({
    auth: state.auth
});

Layout.propTypes = {
    auth: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, {
})(Layout);